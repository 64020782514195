<template>
  <div class="subject-forum center">
    <br>
    <ATitle text="主题论坛"></ATitle>
    <br>
    <Tabs :animated="false">
      <TabPane v-for="(item, index) in list" :label="item.title" :name="item.id"><div class="rich-text" v-html="item.content"></div></TabPane>
    </Tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.$http.get('/meeting/forum/list?type=2',{
    }).then(res => {
      if (res.code === 0) {
        this.list = res.data
      } else {
        this.$Message.warning(res.msg)
      }
    }).catch(err => {
      this.$Message.error(err)
    })
  }
}
</script>

<style lang="less" scoped>
.rich-text {
  padding: 30px;
  min-height: calc(100vh - 600px);
  img {
    max-width: 100%;
  }
}
</style>
<style lang="less">
.subject-forum {
  .ivu-tabs {
    .ivu-tabs-bar {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    .ivu-tabs-ink-bar {
      background-color: #933EFF;
    }
    .ivu-tabs-tab {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.5);
    }
    .ivu-tabs-tab-active {
      color: #FFF;
      font-weight: 600;
    }
  }
  .ivu-tabs-nav-prev, .ivu-tabs-nav-next {
    color: #FFF;
    font-size: 30px;
  }
  .ivu-tabs-nav-scrollable {
    padding: 0 30px;
  }
  .ivu-tabs-nav-scroll {
    border-bottom: 1px #CCC;
  }
}
</style>
